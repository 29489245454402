<template>
  <div class="dashboard-admin d-flex flex-column">
    <div class="dashboard-admin-content content">
      <div class="container grid-content px-0">
        <div
          class="item-menu"
          v-for="(value, index) in listFunction"
          :key="index"
        >
          <h4 class="text-center font-weight-bold">{{ value.title }}</h4>
          <div class="action-item">
            <router-link
              class="d-flex"
              v-for="(item, ind) in value.value"
              :key="ind"
              :to="{ name: item.routeName }"
            >
              <img :src="item.icon" alt="" class="my-auto" />
              <p class="ml-2 my-auto">{{ item.text }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import contentMenu from "@/assets/img/content.png";
import pageMenu from "@/assets/img/page.png";
import catMenu from "@/assets/img/category.png";
// import iconMagnifyingGlass from "@/assets/img/magnifying_glass.png";
import fixedPageMenu from "@/assets/img/fixedpage.png";
// import chapterMenu from "@/assets/img/chapter.png";

export default {
  name: "DashboardAdminEdit",
  data() {
    return {
      listFunction: [
        {
          title: "コンテンツ",
          value: [
            {
              icon: contentMenu,
              text: "作成済みコンテンツ一覧",
              routeName: "list page",
            },
            {
              icon: pageMenu,
              text: "公開設定一覧",
              routeName: "list content",
            },
            // {
            //   icon: iconDefault,
            //   text: "オファー公開設定",
            //   routeName: "list sale",
            // },
          ],
        },
        // {
        //   title: "登錄",
        //   value: [
        //     {
        //       icon: iconDefault,
        //       text: "販売一覧",
        //       routeName: "Menu SaleManagerment",
        //     },
        //   ],
        // },
        {
          title: "カテゴリ",
          value: [
            {
              icon: catMenu,
              text: "カテゴリ管理",
              routeName: "List Category",
            },
          ],
        },
        // {
        //   title: "オファー",
        //   value: [
        //     {
        //       icon: iconMagnifyingGlass,
        //       text: "オファー管理",
        //       routeName: "list sale",
        //     },
        //   ],
        // },
        {
          title: "単発ページ",
          value: [
            {
              icon: fixedPageMenu,
              text: "単発ページ管理",
              routeName: "listFixedPage",
            },
          ],
        },
        // {
        //   title: "章",
        //   value: [
        //     {
        //       icon: chapterMenu,
        //       text: "章管理",
        //       routeName: "List Chapter Managerment",
        //     },
        //   ],
        // },
      ],
    };
  },
  created() {
    let shopId = this.$route.params.shopId;
    if (!shopId) {
      this.listFunction.forEach((obj) => {
        obj.value.forEach((e) => {
          if (e.routeName != null) {
            e.routeName += " domain";
          }
        });
      });
    }
  },
};
</script>
